.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    // .btn {
    //     border: 2px solid gray;
    //     color: gray;
    //     background-color: white;
    //     padding: 8px 20px;
    //     border-radius: 8px;
    //     font-size: 1.2em;
    //     font-weight: bold;
    //   } 
      input[type=file] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
      }         
  }
