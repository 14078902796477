
@import 'lib/variables';
@import 'lib/fileupload';
@import 'lib/angular-theming';
// For bootstrap 4
@import '~bootswatch/dist/materia/_variables.scss';
// @import 'lib/bootstrap';
// @import "~bootstrap/scss/bootstrap";
@import "~bootstrap-scss/bootstrap";
@import '~bootswatch/dist/materia/_bootswatch.scss';
// @import '~angular-material/angular-material.scss';
// Remove the arrow that get's placed on the dropdown toggle buttons
.btn.dropdown-toggle:before {
        content:none;    
}

.clickable {
    cursor: pointer;
}



// Justified button groups
// ----------------------

.btn-group-justified {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
    line-height: .5em;
    .btn,
    .btn-group {
      float: none;
      display: table-cell;
      width: 1%;
      .btn {
        width: 100%;
        line-height: .5em;
      }      
      .dropdown-menu {
        left: auto;
      }
    }
  }