//== fonts
@import url('https://fonts.googleapis.com/css?family=Roboto');
$font-family-base: 'Roboto', 'Helvetica Neue', 'Helvetica' ,' Arial', 'sans-serif';
@import "~bootstrap-scss/_mixins.scss";


$theme-colors: (
    "info": rgb(255, 255, 255),
    // "danger": tomato,
    "secondary": rgb(180, 180, 180),
    // default
    "primary": #2266E0,
    "warning": #ffa726,
    "accent": rgb(213, 66, 51),
    "fluff": rgb(165, 100, 93),
);


$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              #212121 !default;
$gray:                   #666 !default;
$gray-light:             #bbb !default;
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

$brand-primary:         #5f737c !default;
$brand-success:         #4CAF50 !default;
$brand-info:            #9f9f9f !default;
$brand-warning:         #ff9800 !default;
$brand-danger:          #e51c23 !default;
$warning: $brand-warning;
$white: #fff;
// Group buttons require this, otherwise they are purple from Materia
$off-white:             #fefefe !default;
$info:                  $off-white !default;

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.


$state-info-text:                $gray !default;
$state-info-bg:                  $brand-info !default;
$state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%) !default;

@include list-group-item-variant(warning, $brand-warning, $state-info-text);


//== Panels

$panel-bg:                    #fff !default;
// $font-size-base: 1.2rem !default;
// $panel-body-padding:          15px !default;
// $panel-heading-padding:       10px 15px !default;
// $panel-footer-padding:        $panel-heading-padding !default;
// $panel-border-radius:         $border-radius-base !default;

// //** Border color for elements within panels
// $panel-inner-border:          #ddd !default;
// $panel-footer-bg:             #f5f5f5 !default;

// $panel-default-text:          $gray-dark !default;
// $panel-default-border:        #ddd !default;
// $panel-default-heading-bg:    #f5f5f5 !default;

// $panel-primary-text:          #fff !default;
// $panel-primary-border:        $brand-primary !default;
// $panel-primary-heading-bg:    $brand-primary !default;

// $panel-success-text:          #fff !default;
// $panel-success-border:        $state-success-border !default;
// $panel-success-heading-bg:    $brand-success !default;

// $panel-info-text:             #fff !default;
$panel-info-text:                $gray !default;
// $panel-info-border:           $state-info-border !default;
$panel-info-heading-bg:       $brand-info !default;

// $panel-warning-text:          #fff !default;
// $panel-warning-border:        $state-warning-border !default;
// $panel-warning-heading-bg:    $brand-warning !default;

// $panel-danger-text:           #fff !default;
// $panel-danger-border:         $state-danger-border !default;
// $panel-danger-heading-bg:     $brand-danger !default;




